import React from 'react'
import '../css/header.css'
import logo from '../assets/images/logo.png'
//import logoEdit from '../assets/images/logo-horizontal.png'
import { Outlet,  } from "react-router-dom";
export default function Header() {

     // Función para cerrar el menú cuando se selecciona una opción
  const closeMenu = () => {
    const checkbox = document.getElementById('check');
    if (checkbox) {
      checkbox.checked = false; // Desmarcar el checkbox para cerrar el menú
    }
  };

  return (
    <>
    <section className='header'>
        <div className='container'>
            
        <input type="checkbox" id="check" />
          <label htmlFor="check" className="checkbtn">
            <i className="fas fa-bars"></i>
            {/*<i class="fi fi-rr-menu-burger"></i>*/}
          </label>
            <div className='header-container'>
                <div className='header-content'>
                    <ul>
                        <li><a href="/" onClick={closeMenu}>Home</a></li>
                        <li><a href="#services" onClick={closeMenu}>Services</a></li>
                        <li><a href="#about" onClick={closeMenu}>About us</a></li>                        
                        {/*<li><a href="/" onClick={closeMenu}>Company</a></li>*/}
                    </ul>
                </div>
                <div className='header-content'>
                    <img src={logo} alt='comatur logo'/>
                </div>
                <div className='header-content'> 
                    <ul>
                        <li><a href="#contact" onClick={closeMenu}>Contact</a></li>
                        <li className='call-btn'><a href="tel:8003377437"> +52 612 123 66 00</a></li>                      
                    </ul>
                </div>
               
            </div>
        </div>
    </section>
    < Outlet />
    </>
  )
}
