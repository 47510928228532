import React, {useState} from 'react'
import '../css/about.css'

export default function About() {

    const [activeInfo, setActiveInfo] = useState('1');

    const handleInfoClick = (id) => {
        if (activeInfo === id) {
            setActiveInfo(null);
        } else {
            setActiveInfo(id);
        }
    };

  return (
    <>
    <section className='about' id='about'>
            <div className='container'>
            <h4>ABOUT US</h4>
            <h2>Expert Solutions for Cruise Operations   <br /> in the mexican Pacific</h2>
           
                <div className='about-container'>
                    <div className='about-option-list'>
                        <div className={`about-option ${activeInfo === '1' ? 'active1' : ''}`}
                onClick={() => handleInfoClick('1')}>                                                  
                            <h3>Mission </h3>                           
                        </div>
                        <div className={`about-option ${activeInfo === '2' ? 'active2' : ''}`}
                onClick={() => handleInfoClick('2')}>                                              
                            <h3>Vision </h3>                           
                        </div>
                        <div className={`about-option ${activeInfo === '3' ? 'active3' : ''}`}
                onClick={() => handleInfoClick('3')}>                        
                            <h3>History </h3>                           
                        </div>                    
                    </div>
                    <div className='about-text'>
                        <div className='container'>
                          
                            {activeInfo === '1' && (
                            <div className='info_visible'>                            
                                <h4>Our Mission</h4>
                                <p>To provide seamless and efficient port operations for cruise lines and vessels we aim to develop long-term partnerships with a focus on safety, sustainability, and exceptional service.</p>                            
                            </div>
                            )}
                            {activeInfo === '2' && (
                                <div className='info_visible'>                            
                                    <h4>Our Vision </h4>
                                    <p>To be the leading global partner for cruise line and vessels operations, known for our expertise, dedication and commitment to operational excellence.</p>
                                </div>
                            )}
                            {activeInfo === '3' && (
                                <div className='info_visible'>                            
                                    <h4>History</h4>
                                    <p>COMATUR was founded with a singular vision: to redefine the way cruise lines interact with ports across the Pacific. Recognizing a gap in efficient port operations for large-scale cruise ships, we embarked on a mission to streamline processes and provide unmatched service to cruise operators.</p>
                                    <p>Our journey began in 1985 when a small team of maritime professionals came together with extensive experience in port logistics and a passion for improving operational efficiency. Initially focusing on a few key ports, COMATUR quickly gained a reputation for its dedication to excellence and its ability to facilitate smooth, timely port operations.</p>
                                    <p>Over the years, we have expanded our reach, forming strategic partnerships with both local port authorities and international cruise lines. Our focus on innovation, safety, and sustainability has allowed us to grow into one of the leading port service providers in the region. From managing complex schedules to ensuring compliance with all local and international regulations, COMATUR has become synonymous with reliability and operational expertise.</p>
                                    <p>As we look ahead, our commitment remains steadfast: to continue expanding our services globally while adapting to the evolving needs of the cruise industry, we are poised to become a global leader in cruise line and vessels operations, bringing our unique blend of professionalism and innovation to an even wider array of clients and destinations.</p>
                                </div>
                            )}                       
                        </div>
                    </div>
                </div>                             
            </div>
        </section>
    
    </>
  )
}
